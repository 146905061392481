import { SpacingOptions } from '@mui/system';
import { BreakpointsOptions, Components, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import {
  defaultThemeSpacings,
  defaultThemeBreakpoints,
  defaultThemeTypography,
  defaultLightThemeComponents,
  defaultThemeLightPalette,
} from './themes';

export const themeSpacingsFactory = (appInstance: string): SpacingOptions => {
  switch (appInstance) {
    default: {
      return defaultThemeSpacings;
    }
  }
};

export const themeBreakpointsFacotry = (appInstance: string): BreakpointsOptions => {
  switch (appInstance) {
    default: {
      return defaultThemeBreakpoints;
    }
  }
};

export const themeTypographyFactory = (appInstance: string): TypographyOptions => {
  switch (appInstance) {
    default: {
      return defaultThemeTypography;
    }
  }
};

export const themeComponentsFactory = (appInstance: string): Components => {
  switch (appInstance) {
    default: {
      return defaultLightThemeComponents;
    }
  }
};

export const themePaletteFactory = (appInstance: string): PaletteOptions => {
  switch (appInstance) {
    default: {
      return defaultThemeLightPalette;
    }
  }
};
