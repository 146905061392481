import { paymentMethods } from 'src/shared/constants';
import { SelectableItem } from 'src/shared/types';

export const paymentMethodOptions: SelectableItem[] = [
  {
    title: 'insuranceObjectDetails:paymentMethods.yearly',
    value: paymentMethods.yearly,
  },
  {
    title: 'insuranceObjectDetails:paymentMethods.monthly',
    value: paymentMethods.monthly,
  },
];
