import { ComponentType, lazy, LazyExoticComponent, ReactElement } from 'react';
import { AppRoutes } from './appRoutes';

const BaseLayout = lazy(() => import('src/components/layouts/BaseLayout'));
const NoRoutePage = lazy(() => import('src/pages/NoRoutePage'));
const LandingPage = lazy(() => import('src/pages/LandingPage'));
const InfoForOfferPage = lazy(() => import('src/pages/InfoForOfferPage'));
const DeviatingBeginPage = lazy(() => import('src/pages/DeviatingBeginPage'));
const OfferPage = lazy(() => import('src/pages/OfferPage'));
const InsuranceObjectDetailsPage = lazy(
  () => import('src/pages/InsuranceObjectDetailsPage')
);
const SummaryPage = lazy(() => import('src/pages/SummaryPage'));
const OfferCompletedPage = lazy(() => import('src/pages/OfferCompletedPage'));
const SpecialOfferPage = lazy(() => import('src/pages/SpecialOfferPage'));
const SpecialOfferCompletedPage = lazy(
  () => import('src/pages/SpecialOfferCompletedPage')
);

export interface RouteItem {
  path: string;
  component: LazyExoticComponent<ComponentType>;
  validators?: ((component: () => ReactElement) => () => ReactElement)[];
  children?: RouteItem[];
}

const routingConfig = (): RouteItem[] => {
  return [
    {
      component: BaseLayout,
      path: AppRoutes.root,
      children: [
        {
          component: LandingPage,
          path: AppRoutes.root,
        },
        {
          component: InfoForOfferPage,
          path: AppRoutes.infoForOffer,
        },
        {
          component: DeviatingBeginPage,
          path: AppRoutes.deviatingBegin,
        },
        {
          component: OfferPage,
          path: AppRoutes.offerPage,
        },
        {
          component: InsuranceObjectDetailsPage,
          path: AppRoutes.insuranceObjectDetails,
        },
        {
          component: SummaryPage,
          path: AppRoutes.summary,
        },
        {
          component: OfferCompletedPage,
          path: AppRoutes.offerCompleted,
        },
        {
          component: SpecialOfferPage,
          path: AppRoutes.specialOffer,
        },
        {
          component: SpecialOfferCompletedPage,
          path: AppRoutes.specialOfferCompleted,
        },
      ],
    },
    {
      path: '*',
      component: NoRoutePage,
    },
  ];
};

export default routingConfig;
