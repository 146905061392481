import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeModes } from 'src/theming';

export const useStyle = makeStyles((theme: Theme) => {
  const isDarkMode = themeModes.dark === theme.palette.mode;
  return {
    itemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 200100,

      '& .MuiPaper-root': {
        maxWidth: 'auto',
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
      },
    },
    button: {
      width: '100%',
      color: theme.palette.secondary.contrastText,
    },
    buttonText: {
      fontSize: '1.4rem',
    },
    menuItem: {
      display: 'flex',
      minHeight: 44,
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.secondary.main,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&.Mui-focusVisible': {
        backgroundColor: `${theme.palette.secondary.light}!important`,
      },
    },
    menuItemText: {
      flexGrow: 1,
      fontSize: '1.4rem',
      color: theme.palette.primary.contrastText,
      whiteSpace: 'break-spaces',
    },
    iconButton: {
      width: 40,
      height: 40,
      borderRadius: 0,
      backgroundColor: theme.palette.secondary.dark,
      transition: '0.2s',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        opacity: 0.8,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.5rem',
        color: theme.palette.secondary.contrastText,
      },
    },
    additionalActive: {
      marginLeft: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.info.light,
      },
    },
    additionalActionActive: {
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    openAddMenuIcon: {
      color: isDarkMode
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.dark,
    },
  };
});
