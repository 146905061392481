import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { defaultThemeLightColors } from 'src/theming/themes';

export const useCounterStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      height: 22,
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing(1)}`,
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${defaultThemeLightColors.mainBorderColor}`,
      borderRadius: 15,
    },
    btn: {
      padding: 0,
    },
    value: {
      padding: `0 ${theme.spacing(1)}`,
      fontSize: '1.4rem',
      fontWeight: theme.typography.fontWeightBold,
      '& input': {
        padding: 0,
        textAlign: 'center',
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
  };
});
