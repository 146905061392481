export enum insuranceObjectDetailsFieldNames {
  insuranceObjectId = 'id',
  currentInsurer = 'currentInsurer',
  currentInsurerOther = 'currentInsurerOther',
  licensePlate = 'licensePlate',
  firstRegistration = 'initialRegistration',
  hsn = 'hsn',
  tsn = 'tsn',
  fin = 'fin',
  company = 'company',
  firstAndLastName = 'name',
  streetAndHouseNumber = 'street',
  postalCode = 'postalCode',
  city = 'location',
  email = 'email',
  phoneNumber = 'phoneNumber',
  paymentMethod = 'paymentMethod',
  iban = 'iban',
  bic = 'bic',
  bank = 'bank',
}
