import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConnectivityService } from '../connectivity-indicator/use-connectivity-service';
import { ComponentUnloadState } from '../custom-hooks/use-component-unload-state';
import { LoadingState } from '../custom-hooks/use-loading-state';
import { HttpRejectInfo } from './http-reject-info'; // data can be any

export interface HttpRequestOptions {
  isStandardErrorHandlingDisabled?: boolean;
  axiosConfig?: AxiosRequestConfig;
}

type ResolvePromise<T> = (value: T | PromiseLike<T>) => void;
type RejectPromise = (reason?: HttpRejectInfo) => void;
type AxiosCall<T> = () => Promise<AxiosResponse<T>>;

export interface HttpClient {
  readonly isPerformingRequest: boolean;

  get<T>(url: string, options?: HttpRequestOptions): Promise<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

  delete<T>(url: string, options?: HttpRequestOptions): Promise<T>;
}

export class AxiosHttpClient implements HttpClient {
  connectivityService?: ConnectivityService;

  constructor(
    private readonly axios: AxiosInstance,
    private readonly loadingState: LoadingState,
    private readonly componentUnloadState: ComponentUnloadState
  ) {}

  get isPerformingRequest(): boolean {
    return this.loadingState.isLoading;
  }

  get<T>(url: string, options?: HttpRequestOptions): Promise<T> {
    return this.tryPerformRequest(
      () => this.axios.get(url, options?.axiosConfig),
      options
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
    return this.tryPerformRequest<T>(
      () => this.axios.post(url, data, options?.axiosConfig),
      options
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
    return this.tryPerformRequest(
      () => this.axios.put(url, data, options?.axiosConfig),
      options
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
    return this.tryPerformRequest(
      () =>
        this.axios.patch(url, data, {
          ...options?.axiosConfig,
          headers: {
            ...options?.axiosConfig?.headers,
            'Content-Type': 'application/json-patch+json',
          },
        }),
      options
    );
  }

  delete<T>(url: string, options?: HttpRequestOptions): Promise<T> {
    return this.tryPerformRequest(
      () => this.axios.delete(url, options?.axiosConfig),
      options
    );
  }

  private tryPerformRequest<T>(
    axiosCall: AxiosCall<T>,
    options: HttpRequestOptions | undefined
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      // cant run request from 1 async action to  another async action mobx-state-tree
      // can make two requests in order
      // if (!this.trySetLoadingState()) {
      //     reject(HttpRejectInfo.otherRequestIsBeingExecuted());
      //     return;
      // }

      axiosCall()
        .then((response) => {
          this.handleResponse(response, resolve, reject);
        })
        .catch((error: AxiosError) => {
          this.handleError(error, !!options?.isStandardErrorHandlingDisabled, reject);
        })
        .finally(() => this.resetLoadingState());
    });
  }

  private handleResponse<T>(
    response: AxiosResponse<T>,
    resolve: ResolvePromise<T>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reject: RejectPromise
  ): void {
    this.connectivityService?.reportConnectionSuccess();
    // if (this.componentUnloadState.isComponentUnloaded) {
    //   reject(HttpRejectInfo.componentUnloaded());
    //   return;
    // }

    resolve(response?.data);
  }

  private handleError(
    error: AxiosError,
    isStandardErrorHandlingDisabled: boolean,
    reject: RejectPromise
  ): void {
    if (isStandardErrorHandlingDisabled) reject(HttpRejectInfo.notHandled(error));

    if (
      this.tryHandleErrorResponse(error, reject) ||
      this.tryHandleMissingResponse(error, reject)
    ) {
      return;
    }

    this.handleErroneousRequest(error, reject);
  }

  private tryHandleErrorResponse(error: AxiosError, reject: RejectPromise): boolean {
    const response = error.response;
    if (!response) return false;

    // TODO: notify errors

    if (response.status === 500) {
      reject(HttpRejectInfo.handled500InternalServerError(error));
    } else if (response.status === 404) {
      reject(HttpRejectInfo.handled404NotFound(error));
    } else {
      reject(HttpRejectInfo.notHandled(error));
    }

    this.connectivityService?.reportConnectionSuccess();
    return true;
  }

  private tryHandleMissingResponse(error: AxiosError, reject: RejectPromise): boolean {
    const request = error.request;
    if (!request) {
      return false;
    }

    this.connectivityService?.reportConnectionError();
    reject(HttpRejectInfo.handledServerNotReachable(error));
    return true;
  }

  private handleErroneousRequest(error: AxiosError, reject: RejectPromise): void {
    // eslint-disable-next-line no-console
    console.error(typeof error.toJSON === 'function' ? error.toJSON() : error);

    // TODO: notify errors

    reject(HttpRejectInfo.handledErroneousRequest(error));
  }

  private trySetLoadingState(): boolean {
    if (this.loadingState.isLoading) {
      return false;
    }

    this.loadingState.isLoading = true;
    return true;
  }

  private resetLoadingState(): void {
    this.loadingState.isLoading = false;
  }
}
