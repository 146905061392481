import React, { ReactElement, SyntheticEvent, useMemo } from 'react';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { FilePanelFileFormats } from '../FilesPanel/components/FileItem/FilePanelFileFormats';
import { useDropZoneItemStyles } from './DropZoneItemStyles';
import pdfSrc from '../FilesPanel/components/FileItem/icons/pdf.png';
import docSrc from '../FilesPanel/components/FileItem/icons/doc.png';
import xlsSrc from '../FilesPanel/components/FileItem/icons/xls.png';
import pngSrc from '../FilesPanel/components/FileItem/icons/png.png';
import jpgSrc from '../FilesPanel/components/FileItem/icons/jpg.png';
import csvSrc from '../FilesPanel/components/FileItem/icons/csv.png';
import svgSrc from '../FilesPanel/components/FileItem/icons/svg.png';
import msgSrc from '../FilesPanel/components/FileItem/icons/msg.png';

interface DropZoneItemProps {
  onDelete: (id: string) => void;
  item: { id: string; file: File };
}

export const DropZoneItem = ({ onDelete, item }: DropZoneItemProps): ReactElement => {
  const classes = useDropZoneItemStyles();

  const fileFormat = useMemo(
    () => (item.file.name ? item.file.name.split('.').pop() : ''),
    [item]
  );

  const stopPropagation = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  const handleDelete = (e: SyntheticEvent): void => {
    stopPropagation(e);
    onDelete(item.id);
  };

  return (
    <div className={classes.container}>
      <IconButton onClick={handleDelete} className={classes.deleteBtn}>
        <CloseIcon />
      </IconButton>
      <div className={classes.iconContainer} onClickCapture={stopPropagation}>
        {(fileFormat === FilePanelFileFormats.docx ||
          fileFormat === FilePanelFileFormats.doc) && (
          <img alt={item.file.name} src={docSrc} />
        )}
        {fileFormat === FilePanelFileFormats.pdf && (
          <img alt={item.file.name} src={pdfSrc} />
        )}
        {(fileFormat === FilePanelFileFormats.xls ||
          fileFormat === FilePanelFileFormats.xlsx) && (
          <img alt={item.file.name} src={xlsSrc} />
        )}
        {fileFormat === FilePanelFileFormats.png && (
          <img alt={item.file.name} src={pngSrc} />
        )}
        {fileFormat === FilePanelFileFormats.jpg && (
          <img alt={item.file.name} src={jpgSrc} />
        )}
        {fileFormat === FilePanelFileFormats.csv && (
          <img alt={item.file.name} src={csvSrc} />
        )}
        {fileFormat === FilePanelFileFormats.svg && (
          <img alt={item.file.name} src={svgSrc} />
        )}
        {fileFormat === FilePanelFileFormats.msg && (
          <img alt={item.file.name} src={msgSrc} />
        )}
      </div>

      <span className={classes.fileName} onClickCapture={stopPropagation}>
        {item.file.name}
      </span>
    </div>
  );
};
