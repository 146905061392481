import React, { memo, useMemo } from 'react';
import { useContext, createContext } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { StorageService } from 'src/lib/storageService';
import { DateTimeService } from 'src/lib/dateTimeService/dateTimeService';
import { StoreModel } from './models';
import { createStore } from './createStore';
import { defaultStoreState } from './defaultStoreState';

const StoreContext = createContext<StoreModel>({} as StoreModel);

const StoreContextProvider = StoreContext.Provider;

export const useStore = (): StoreModel => useContext<StoreModel>(StoreContext);

interface IStoreProvider {
  children: JSX.Element | JSX.Element[];
}

export const StoreProvider = memo(({ children }: IStoreProvider): JSX.Element => {
  const httpClient = useHttpClient();
  const storageService = new StorageService(localStorage);
  const dateTimeService = new DateTimeService();

  const store = useMemo(
    () =>
      createStore(defaultStoreState, {
        httpClient,
        storageService,
        dateTimeService,
      }),
    []
  );
  return <StoreContextProvider value={store}>{children}</StoreContextProvider>;
});

export const withStoreProvider = (component: () => React.ReactElement) => () =>
  <StoreProvider>{component()}</StoreProvider>;
