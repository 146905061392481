import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { CompleteOfferDto } from 'src/shared/types';

export const ConfirmationsStore = types
  .model('ConfirmationsStore', {
    agreeToSendInformation: types.maybeNull(types.boolean),
    protectionRegulation: types.maybeNull(types.boolean),
    brokerageContract: types.maybeNull(types.boolean),
    generalConditionsForMotor: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    let initialState: IConfirmationsStoreSnapshotOut;

    const {
      env: { httpClient },
    } = getEnv(self);

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetConfirmationsStore(): void {
        applySnapshot(self, initialState);
      },
      setConfirmation(name: string, value: boolean) {
        applySnapshot(self, { ...self, [name]: value });
      },
      completeOffer: flow(function* (data: CompleteOfferDto) {
        yield httpClient.post(`insurance/complete`, data);
      }),
    };
  })
  .views((self) => {
    return {
      get isValid(): boolean {
        const { protectionRegulation, brokerageContract, generalConditionsForMotor } =
          self;
        return protectionRegulation && brokerageContract && generalConditionsForMotor;
      },
    };
  });

export type ConfirmationsStoreModel = Instance<typeof ConfirmationsStore>;
export type IConfirmationsStoreSnapshotOut = SnapshotOut<typeof ConfirmationsStore>;
export type IConfirmationsStoreSnapshotIn = SnapshotIn<typeof ConfirmationsStore>;
