import { useEffect, useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';

export const useEmailInterceptor = (email: string): void => {
  const axios = useAxios();

  const key = useMemo(() => window.atob(email), [email]);

  useEffect(() => {
    let reqInterceptor: number;
    let resInterceptor: number;
    if (email) {
      const emailInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (config.headers) {
          config.headers['email'] = key;
        }
        return config;
      };

      reqInterceptor = axios.interceptors.request.use(emailInterceptor);
    }
    return () => {
      if (reqInterceptor && resInterceptor) {
        axios.interceptors.request.eject(reqInterceptor);
      }
    };
  }, [email]);
};
