import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';
import { useTimestamp } from './useTimestamp';

export const useTimestampInterceptor = (): void => {
  const axios = useAxios();
  const timestamp = useTimestamp();

  useEffect(() => {
    let resInterceptor: number;
    const timestampInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
      if (config.headers) {
        config.headers['timestamp'] = timestamp;
      }
      return config;
    };

    const reqInterceptor = axios.interceptors.request.use(timestampInterceptor);

    return () => {
      if (reqInterceptor && resInterceptor) {
        axios.interceptors.request.eject(reqInterceptor);
      }
    };
  }, []);
};
