import { makeStyles } from '@mui/styles';

export const useCheckboxStyles = makeStyles(() => {
  return {
    root: {
      padding: 0,
      '& .MuiSvgIcon-root': {
        width: '24px!important',
        height: '24px!important',
      },
    },
  };
});
