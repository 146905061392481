import { PaletteOptions } from '@mui/material';
import { defaultThemeLightColors } from './defaultThemeColors';
import { themeModes } from 'src/theming/themeModes';

export const defaultThemeLightPalette: PaletteOptions = {
  mode: themeModes.light,
  primary: {
    main: defaultThemeLightColors.primaryMainColor,
    light: defaultThemeLightColors.primaryLightColor,
    dark: defaultThemeLightColors.primaryDarkColor,
    contrastText: defaultThemeLightColors.primaryContrastText,
  },
  secondary: {
    main: defaultThemeLightColors.secondaryMainColor,
    light: defaultThemeLightColors.secondaryLightColor,
    dark: defaultThemeLightColors.secondaryDarkColor,
    contrastText: defaultThemeLightColors.secondaryContrastText,
  },
  info: {
    main: defaultThemeLightColors.infoMainColor,
    light: defaultThemeLightColors.infoLightColor,
    dark: defaultThemeLightColors.infoDarkColor,
    contrastText: defaultThemeLightColors.infoContrastText,
  },
  error: {
    main: defaultThemeLightColors.errorMainColor,
    light: defaultThemeLightColors.errorLightColor,
    dark: defaultThemeLightColors.errorDarkColor,
    contrastText: defaultThemeLightColors.errorContrastTextColor,
  },
};
