import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useStore } from 'src/mst/StoreProvider';
import { getUserStore } from 'src/mst/selectors';

const useUserNameStyles = makeStyles((theme: Theme) => {
  return {
    userName: {
      fontSize: '1.8rem',
      fontWeight: theme.typography.fontWeightRegular,
    },
  };
});

interface UserNameProps {
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
}

export const UserName = observer(({ align = 'right' }: UserNameProps): ReactElement => {
  const classes = useUserNameStyles();
  const store = useStore();
  const { name } = getUserStore(store);

  if (!name) {
    return null;
  }
  return (
    <Typography variant='h4' align={align} className={classes.userName}>
      {name}
    </Typography>
  );
});
