import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useLanguageDetectorStyle = makeStyles((theme: Theme) => {
  return {
    langBtn: {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText,
      '& p': {
        fontSize: '1.6rem',
        fontWeight: theme.typography.fontWeightBold,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 25,
        color: theme.palette.primary.contrastText,
      },
    },
  };
});
