import React, { ReactElement } from 'react';

import { Checkbox as MuiCheckbox } from '@mui/material';

import { useCheckboxStyles } from './CheckboxStyles';

interface CheckboxProps {
  name: string;
  handleChange: (name: string, value: boolean) => void;
  checked: boolean;
  color?: 'secondary' | 'default' | 'primary' | 'error' | 'info' | 'success' | 'warning';
}

export const Checkbox = ({
  color = 'secondary',
  name,
  handleChange,
  checked,
}: CheckboxProps): ReactElement => {
  const classes = useCheckboxStyles();

  const onChange = (): void => {
    handleChange(name, !checked);
  };

  return (
    <MuiCheckbox classes={classes} color={color} onChange={onChange} checked={checked} />
  );
};
