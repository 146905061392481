import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { useSurfaceAStyles } from './SurfaceAStyles';

interface SurfaceAProps {
  children: ReactElement;
  className?: string;
}

export const SurfaceA = ({ children, className }: SurfaceAProps): ReactElement => {
  const classes = useSurfaceAStyles();

  const classNames = className ? clsx(classes.container, className) : classes.container;

  return <div className={classNames}>{children}</div>;
};
