import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
  getRoot,
} from 'mobx-state-tree';

import { insuranceObjectDetailsFieldNames } from '../insuranceObjectDetails';
import { GeneralDataDto } from 'src/shared/types';
import {
  getInfoForOfferStore,
  getInsuranceObjectDetailsStore,
  getOfferStore,
} from 'src/mst/selectors';
import { StoreModel } from '../root';

export const UserStore = types
  .model('UserStore', {
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    numberOfInsuredCars: types.maybeNull(types.number),
    deviatingBegin: types.maybeNull(types.string),
    isValid: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    let initialState: IUserStoreSnapshotOut;

    const {
      env: { httpClient },
    } = getEnv(self);

    const store: StoreModel = getRoot(self);
    const infoForOfferStore = getInfoForOfferStore(store);
    const insuranceObjectDetails = getInsuranceObjectDetailsStore(store);
    const offerStore = getOfferStore(store);

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetUserStore(): void {
        applySnapshot(self, initialState);
      },
      setDeviatingBegin(value: string | null): void {
        applySnapshot(self, {
          ...self,
          deviatingBegin: value,
        });
      },
      fetchUserData: flow(function* () {
        try {
          const data: GeneralDataDto = yield httpClient.post(`insurance/get-data`);
          if (data) {
            applySnapshot(self, {
              ...self,
              name: data.name,
              email: data.email,
              numberOfInsuredCars: data.alreadyCustomer,
              phoneNumber: data.phoneNumber,
              isValid: true,
            });
            infoForOfferStore.setChangeInsuranceObjectCount(data.changeCarCount || 0);
            infoForOfferStore.setPostalCode(data.postcode);
            insuranceObjectDetails.setInsuranceObjects(
              data.cars?.map((item) => ({
                [insuranceObjectDetailsFieldNames.insuranceObjectId]: item.id,
                [insuranceObjectDetailsFieldNames.licensePlate]: item.licensePlate,
                [insuranceObjectDetailsFieldNames.currentInsurer]: item.currentInsurer,
                [insuranceObjectDetailsFieldNames.firstRegistration]:
                  item.initialRegistration,
                [insuranceObjectDetailsFieldNames.hsn]: item.hsn,
                [insuranceObjectDetailsFieldNames.tsn]: item.tsn,
                [insuranceObjectDetailsFieldNames.fin]: item.fin,
              }))
            );
            insuranceObjectDetails.setValues({
              [insuranceObjectDetailsFieldNames.email]: data.email,
              [insuranceObjectDetailsFieldNames.firstAndLastName]: data.name,
              [insuranceObjectDetailsFieldNames.phoneNumber]: data.phoneNumber,
              [insuranceObjectDetailsFieldNames.company]: data.company,
              [insuranceObjectDetailsFieldNames.streetAndHouseNumber]: data.street,
              [insuranceObjectDetailsFieldNames.city]: data.location,
              [insuranceObjectDetailsFieldNames.paymentMethod]: data.paymentMethod
                ? data.paymentMethod.toString()
                : null,
              [insuranceObjectDetailsFieldNames.iban]: data.iban,
              [insuranceObjectDetailsFieldNames.bic]: data.bic,
              [insuranceObjectDetailsFieldNames.bank]: data.bank,
            });
            offerStore.setValues(
              data.carTarif?.carSinglePremiumPerMonth,
              data.changeCarCount + data.newCarCount
            );
          }
        } catch {
          applySnapshot(self, { ...self, isValid: false });
        }
      }),
    };
  })
  .views((self) => {
    return {
      get isExistingUser(): boolean {
        return Boolean(self.numberOfInsuredCars);
      },
    };
  });

export type UserStoreModel = Instance<typeof UserStore>;
export type IUserStoreSnapshotOut = SnapshotOut<typeof UserStore>;
export type IUserStoreSnapshotIn = SnapshotIn<typeof UserStore>;
