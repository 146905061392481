import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { InfoForOfferStore } from './infoForOffer';
import { UserStore } from './user';
import { OfferStore } from './offer';
import { SpecialOfferStore } from './speicalOffer';
import { InsuranceObjectDetailsStore } from './insuranceObjectDetails';
import { ConfirmationsStore } from './confirmations';

export const Store = types.model('Root', {
  infoForOfferStore: InfoForOfferStore,
  user: UserStore,
  offer: OfferStore,
  specialOffer: SpecialOfferStore,
  insuranceObjectDetails: InsuranceObjectDetailsStore,
  confirmations: ConfirmationsStore,
});

export type StoreModel = Instance<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;
