import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { defaultThemeLightColors } from 'src/theming/themes';

export const useSurfaceAStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${defaultThemeLightColors.mainBorderColor}`,
      borderRadius: 8,
    },
  };
});
