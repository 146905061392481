import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ResponseError } from '../types';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { reactQueryKeys } from '../constants';

export const useValidatePostalCode = (
  postalCode: string
): {
  error: ResponseError;
  isValid: boolean;
  isValidationLoading: boolean;
} => {
  const httpClient = useHttpClient();
  const [isValid, setValid] = useState<boolean>(false);

  const { isLoading, error } = useQuery<unknown, ResponseError>(
    [reactQueryKeys.validatePostCode, postalCode],
    async () => {
      await httpClient.get(`postcodes/validate?postalCode=${postalCode}`);
      return null;
    },
    {
      retry: 0,
      enabled: typeof postalCode === 'string',
      onSuccess: () => {
        setValid(true);
      },
      onError: () => {
        setValid(false);
      },
    }
  );

  return { isValid, isValidationLoading: isLoading, error };
};
