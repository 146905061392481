export enum AppRoutes {
  root = '',
  infoForOffer = '/info-for-offer',
  deviatingBegin = '/deviating-begin',
  offerPage = '/offer',
  insuranceObjectDetails = '/insurance-object-details',
  summary = '/summary',
  offerCompleted = '/offer-completed',
  specialOffer = '/special-offer',
  specialOfferCompleted = '/special-offer-completed',
}
