import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
} from 'mobx-state-tree';

export const OfferStore = types
  .model('OfferStore', {
    rentalCarSinglePremium: types.maybeNull(types.number),
    totalBonusPerMonthSinglePremium: types.maybeNull(types.number),
    totalBonusPerMonthSinglePremiumWithGross: types.maybeNull(types.number),
    sumInsured: types.maybeNull(types.number),
    coinsuranceOfOwnDamageUpTo: types.maybeNull(types.number),
    fullyComprehensive: types.maybeNull(types.number),
    partiallyComprehensive: types.maybeNull(types.number),
    consequentialDamageUpTo: types.maybeNull(types.number),
  })
  .actions((self) => {
    let initialState: IOfferStoreSnapshotOut;

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetOfferStoreData(): void {
        applySnapshot(self, initialState);
      },
      setValues(carSinglePremiumPerMonth: number, insuranceObjectCount: number): void {
        applySnapshot(self, {
          ...self,
          rentalCarSinglePremium: carSinglePremiumPerMonth,
          totalBonusPerMonthSinglePremium:
            insuranceObjectCount * carSinglePremiumPerMonth,
          totalBonusPerMonthSinglePremiumWithGross:
            insuranceObjectCount * carSinglePremiumPerMonth * 12,
        });
      },
    };
  })
  .views((self) => {
    return {
      get offerCalculationResult(): {
        rentalCarSinglePremium: number;
        totalBonusPerMonthSinglePremium: number;
        totalBonusPerMonthSinglePremiumWithGross: number;
      } {
        const {
          rentalCarSinglePremium,
          totalBonusPerMonthSinglePremium,
          totalBonusPerMonthSinglePremiumWithGross,
        } = self;
        return {
          rentalCarSinglePremium,
          totalBonusPerMonthSinglePremium,
          totalBonusPerMonthSinglePremiumWithGross,
        };
      },
      get offerPerformanceOverview(): {
        sumInsured: number;
        coinsuranceOfOwnDamageUpTo: number;
        fullyComprehensive: number;
        partiallyComprehensive: number;
        consequentialDamageUpTo: number;
      } {
        const {
          sumInsured,
          coinsuranceOfOwnDamageUpTo,
          fullyComprehensive,
          partiallyComprehensive,
          consequentialDamageUpTo,
        } = self;
        return {
          sumInsured,
          coinsuranceOfOwnDamageUpTo,
          fullyComprehensive,
          partiallyComprehensive,
          consequentialDamageUpTo,
        };
      },
    };
  });

export type OfferStoreModel = Instance<typeof OfferStore>;
export type IOfferStoreSnapshotOut = SnapshotOut<typeof OfferStore>;
export type IOfferStoreSnapshotIn = SnapshotIn<typeof OfferStore>;
