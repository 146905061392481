import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDropZoneStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      minHeight: 145,
      borderRadius: 5,
      border: `1px dashed #D2D2D2`,
      cursor: 'pointer',
      backgroundColor: '#F9F9F9',
    },
    presentation: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemsContainer: {
      display: 'flex',
      gap: theme.spacing(5),
      padding: theme.spacing(4),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    addAttachmentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    addAttachmentText: {
      fontSize: '1.2rem',
    },
    addAttachmentIcon: {
      fontSize: '4.8rem',
    },
  };
});
