import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
  getRoot,
} from 'mobx-state-tree';

import { StoreModel } from '../root';
import { AppRoutes } from 'src/routing';
import { CarTarifDto, InsuranceTypeFlag } from 'src/shared/types';
import { getOfferStore } from 'src/mst/selectors';
import { getInfoForOfferStore, getUserStore } from 'src/mst/selectors';

export const InfoForOfferStore = types
  .model('InfoForOfferStore', {
    insuranceType: types.number,
    newInsuranceObjectCount: types.maybeNull(types.number),
    changeInsuranceObjectCount: types.maybeNull(types.number),
    postalCode: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IInfoForOfferStoreSnapshotOut;

    const {
      env: { httpClient },
    } = getEnv(self);

    const store: StoreModel = getRoot(self);
    const offerStore = getOfferStore(store);
    const infoForOfferStore = getInfoForOfferStore(store);

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetUserData(): void {
        applySnapshot(self, initialState);
      },
      setInsuranceType(value: InsuranceTypeFlag): void {
        applySnapshot(self, {
          ...self,
          insuranceType: value,
        });
      },
      setNewInsuranceObjectCount(value: number): void {
        if (value >= 0) {
          applySnapshot(self, { ...self, newInsuranceObjectCount: value });
        }
      },
      setChangeInsuranceObjectCount(value: number): void {
        if (value >= 0) {
          applySnapshot(self, { ...self, changeInsuranceObjectCount: value });
        }
      },
      setPostalCode(value: string) {
        if (typeof value === 'string') {
          if (value.length < 6) {
            applySnapshot(self, { ...self, postalCode: value });
          }
        } else {
          applySnapshot(self, { ...self, postalCode: null });
        }
      },
      calculateContribution: flow(function* () {
        const params: {
          postcode: string;
          insuranceType: number;
          newCarCount: number;
          changeCarCount: number;
        } = {
          postcode: self.postalCode ? self.postalCode.toString() : null,
          insuranceType: self.insuranceType,
          newCarCount: self.newInsuranceObjectCount,
          changeCarCount: self.changeInsuranceObjectCount,
        };
        const data: CarTarifDto = yield httpClient.post(`insurance/get-tarif`, params);
        let carCount = 0;
        if (
          (self.insuranceType & InsuranceTypeFlag.InsuranceChange) ==
          InsuranceTypeFlag.InsuranceChange
        ) {
          carCount += infoForOfferStore.changeInsuranceObjectCount;
        }
        if (
          (self.insuranceType & InsuranceTypeFlag.NewRegistration) ==
          InsuranceTypeFlag.NewRegistration
        ) {
          carCount += infoForOfferStore.newInsuranceObjectCount;
        }
        if (data) {
          offerStore.setValues(data.carSinglePremiumPerMonth, carCount);
        }
      }),
    };
  })
  .views((self) => {
    const store: StoreModel = getRoot(self);
    const { numberOfInsuredCars } = getUserStore(store);
    return {
      get nextStepPath(): AppRoutes {
        if (typeof self.newInsuranceObjectCount !== 'number') {
          return null;
        }
        if (self.newInsuranceObjectCount + numberOfInsuredCars < 20) {
          return AppRoutes.offerPage;
        } else if (self.newInsuranceObjectCount + numberOfInsuredCars >= 20) {
          return AppRoutes.specialOffer;
        }
        return null;
      },
      get isValid(): boolean {
        const {
          insuranceType,
          newInsuranceObjectCount,
          changeInsuranceObjectCount,
          postalCode,
        } = self;
        if (insuranceType === InsuranceTypeFlag.None) {
          return false;
        }
        if (String(postalCode).length !== 4 && String(postalCode).length !== 5) {
          return false;
        }
        if (insuranceType === InsuranceTypeFlag.NewRegistration) {
          return newInsuranceObjectCount > 0;
        }
        if (insuranceType === InsuranceTypeFlag.InsuranceChange) {
          return changeInsuranceObjectCount > 0;
        }
        if (
          (insuranceType & InsuranceTypeFlag.NewRegistration) ===
            InsuranceTypeFlag.NewRegistration &&
          (insuranceType & InsuranceTypeFlag.InsuranceChange) ===
            InsuranceTypeFlag.InsuranceChange
        ) {
          return newInsuranceObjectCount + changeInsuranceObjectCount > 0;
        }
        return false;
      },
      get carCount(): number {
        const { insuranceType, newInsuranceObjectCount, changeInsuranceObjectCount } =
          self;
        let count = 0;
        if (insuranceType == InsuranceTypeFlag.None) {
          return count;
        }
        if (
          (insuranceType & InsuranceTypeFlag.InsuranceChange) ==
          InsuranceTypeFlag.InsuranceChange
        ) {
          count += changeInsuranceObjectCount;
        }
        if (
          (insuranceType & InsuranceTypeFlag.NewRegistration) ==
          InsuranceTypeFlag.NewRegistration
        ) {
          count += newInsuranceObjectCount;
        }
        return count;
      },
    };
  });

export type InfoForOfferStoreModel = Instance<typeof InfoForOfferStore>;
export type IInfoForOfferStoreSnapshotOut = SnapshotOut<typeof InfoForOfferStore>;
export type IInfoForOfferStoreSnapshotIn = SnapshotIn<typeof InfoForOfferStore>;
