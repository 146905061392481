import { useQuery } from '@tanstack/react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { reactQueryKeys } from '../constants';
import { SelectableItem } from '../types';

export const useGetInsuranceCompanies = (): SelectableItem[] => {
  const httpClient = useHttpClient();

  const { data } = useQuery(
    [reactQueryKeys.insuranceCompanies],
    async () => {
      const response: string[] = await httpClient.get('insurance-companies');
      return response.map((item) => ({
        title: item,
        value: item,
      }));
    },
    {
      staleTime: 300000,
    }
  );

  return data;
};
