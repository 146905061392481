import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useLoaderStyles = makeStyles((theme: Theme) => {
  return {
    loaderContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
    loader: {
      color: theme.palette.secondary.main,
    },
  };
});
