import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { useBackButtonStyles } from './BackButtonStyles';

interface BackButtonProps {
  to: string;
  onClick?: () => void;
}

export const BackButton = ({ to, onClick }: BackButtonProps): ReactElement => {
  const classes = useBackButtonStyles();
  const { t } = useTranslation(['common']);
  return (
    <Button
      component={Link}
      to={to}
      color='info'
      className={classes.backBtn}
      onClick={onClick}
    >
      {t('backBtn')}
    </Button>
  );
};
