import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
} from 'mobx-state-tree';

import {
  InsuranceObjectDetailsItemStore,
  IInsuranceObjectDetailsItemStoreSnapshotIn,
} from './insuranceObjectDetailsItem';
import { insuranceObjectDetailsFieldNames } from './constants';

export const InsuranceObjectDetailsStore = types
  .model('InsuranceObjectDetailsStore', {
    isFormValid: types.maybeNull(types.boolean),
    isBillingAddressValid: types.maybeNull(types.boolean),
    isContactDataValid: types.maybeNull(types.boolean),
    isPaymentDataValid: types.maybeNull(types.boolean),
    items: types.array(InsuranceObjectDetailsItemStore),
    [insuranceObjectDetailsFieldNames.company]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.firstAndLastName]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.streetAndHouseNumber]: types.maybeNull(
      types.string
    ),
    [insuranceObjectDetailsFieldNames.city]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.email]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.phoneNumber]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.paymentMethod]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.iban]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.bic]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.bank]: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IInsuranceObjectDetailsStoreSnapshotOut;
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      validate() {
        const allItemsValid = self.items.every((item) => item.isValid);
        const { isBillingAddressValid, isContactDataValid, isPaymentDataValid } = self;

        const isValid =
          allItemsValid &&
          isBillingAddressValid &&
          isContactDataValid &&
          isPaymentDataValid;

        self.isFormValid = isValid;
      },
      setPaymentDataValid(value: boolean): void {
        self.isPaymentDataValid = value;
        this.validate();
      },
      setBillingAddressValid(value: boolean): void {
        self.isBillingAddressValid = value;
        this.validate();
      },
      setContactDataValid(value: boolean): void {
        self.isContactDataValid = value;
        this.validate();
      },
      resetInsuranceObjectDetailsStoreData(): void {
        applySnapshot(self, initialState);
      },
      setFieldValue(name: string, value: unknown): void {
        applySnapshot(self, {
          ...self,
          [name]: value,
        });
        this.validate();
      },
      setValues(values: object): void {
        applySnapshot(self, { ...self, ...values });
      },
      setInsuranceObjects(
        insuranceObjects: IInsuranceObjectDetailsItemStoreSnapshotIn[]
      ): void {
        applySnapshot(self, {
          ...self,
          items: insuranceObjects,
        });
      },
    };
  });

export type InsuranceObjectDetailsStoreModel = Instance<
  typeof InsuranceObjectDetailsStore
>;
export type IInsuranceObjectDetailsStoreSnapshotOut = SnapshotOut<
  typeof InsuranceObjectDetailsStore
>;
export type IInsuranceObjectDetailsStoreSnapshotIn = SnapshotIn<
  typeof InsuranceObjectDetailsStore
>;
