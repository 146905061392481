import React, { BaseSyntheticEvent, ReactElement } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  MenuItem as MaterialMenuItem,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStyle } from './NestedMenuStyles';
import { MenuItem } from '.';

export interface NestedMenuItemProps {
  item?: MenuItem;
  isDisabled?: boolean;
  closePopup?: () => void;
  namespaces?: string[];
}

export const NestedMenuItem = (props: NestedMenuItemProps): ReactElement | null => {
  const classes = useStyle();
  const theme = useTheme();

  const { item, isDisabled, closePopup, namespaces } = props;

  const { t } = useTranslation(namespaces);

  const handleAdditionalAction = (e: BaseSyntheticEvent, cb?: () => void): void => {
    e.stopPropagation();
    if (typeof cb === 'function') {
      cb();
    }
  };

  if (!item) {
    return null;
  }

  return (
    <PopupState variant='popover'>
      {(popupState) => {
        return (
          <div>
            <MaterialMenuItem
              disabled={isDisabled}
              className={classes.menuItem}
              style={{
                backgroundColor: item.isActive ? theme.palette.info.light : 'inherit',
              }}
              {...bindTrigger(popupState)}
            >
              <Typography variant='inherit' className={classes.menuItemText}>
                {t(item.title || '')}
              </Typography>
              {item.icon ? (
                <IconButton
                  size='small'
                  onClick={(e: BaseSyntheticEvent) =>
                    handleAdditionalAction(e, item?.additionalAction)
                  }
                  className={clsx(
                    classes.additionalActive,
                    item.isAdditionalActionActive ? classes.additionalActionActive : null
                  )}
                >
                  {item.icon}
                </IconButton>
              ) : null}
              <ArrowForwardIosIcon className={classes.openAddMenuIcon} />
            </MaterialMenuItem>
            <Popover
              {...bindPopover(popupState)}
              elevation={2}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className={classes.itemsContainer}
            >
              {item.nestedMenu?.map((item: MenuItem) =>
                !item.nestedMenu ? (
                  <MaterialMenuItem
                    disabled={isDisabled}
                    key={item.title}
                    className={classes.menuItem}
                    onClick={() => {
                      if (typeof item.action === 'function') {
                        item.action();
                      }
                      popupState.close();
                      if (closePopup) {
                        closePopup();
                      }
                    }}
                    style={{
                      backgroundColor: item.isActive
                        ? theme.palette.primary.dark
                        : 'inherit',
                    }}
                  >
                    <Typography variant='inherit' className={classes.menuItemText}>
                      {t(item.title || '')}
                    </Typography>
                    {item.icon ? (
                      <IconButton
                        size='small'
                        onClick={(e: BaseSyntheticEvent) =>
                          handleAdditionalAction(e, item.additionalAction)
                        }
                        className={clsx(
                          classes.additionalActive,
                          item.isAdditionalActionActive
                            ? classes.additionalActionActive
                            : null
                        )}
                      >
                        {item.icon}
                      </IconButton>
                    ) : null}
                  </MaterialMenuItem>
                ) : (
                  <NestedMenuItem key={item.title} item={item} isDisabled={isDisabled} />
                )
              )}
            </Popover>
          </div>
        );
      }}
    </PopupState>
  );
};
