import { makeStyles } from '@mui/styles';

export const useCheckedListItemStyles = makeStyles(() => {
  return {
    listItem: {
      paddingLeft: 0,
      paddingTop: 2,
      paddingBottom: 2,
    },
    listItemIcon: {
      minWidth: 20,
    },
    icon: {
      fontSize: '1.2rem',
      color: '#68C14D',
    },
    text: {
      '& .MuiTypography-root': {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
      },
    },
  };
});
