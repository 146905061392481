import React, { ReactElement, useMemo } from 'react';

import { withTooltip } from 'src/shared/hocs';

import { FilePanelFileFormats } from './FilePanelFileFormats';
import { useFileItemStyles } from './FileItemStyles';
import pdfSrc from './icons/pdf.png';
import docSrc from './icons/doc.png';
import xlsSrc from './icons/xls.png';
import pngSrc from './icons/png.png';
import jpgSrc from './icons/jpg.png';
import csvSrc from './icons/csv.png';
import svgSrc from './icons/svg.png';
import msgSrc from './icons/msg.png';

export interface FileItemProps {
  id: string;
  alt: string;
  fileName: string;
  handleClick: (id: string) => void;
}

export const FileItem = withTooltip(
  ({ alt, id, fileName, handleClick }: FileItemProps): ReactElement => {
    const classes = useFileItemStyles();

    const fileFormat = useMemo(
      () => (fileName ? fileName.split('.').pop().toLowerCase().split('-').pop() : ''),
      [fileName]
    );

    const onClick = (): void => {
      handleClick(id);
    };

    return (
      <button className={classes.container} onClick={onClick}>
        <div className={classes.iconContainer}>
          {(fileFormat === FilePanelFileFormats.docx ||
            fileFormat === FilePanelFileFormats.doc) && <img alt={alt} src={docSrc} />}
          {fileFormat === FilePanelFileFormats.pdf && <img alt={alt} src={pdfSrc} />}
          {(fileFormat === FilePanelFileFormats.xls ||
            fileFormat === FilePanelFileFormats.xlsx) && <img alt={alt} src={xlsSrc} />}
          {fileFormat === FilePanelFileFormats.png && <img alt={alt} src={pngSrc} />}
          {fileFormat === FilePanelFileFormats.jpg && <img alt={alt} src={jpgSrc} />}
          {fileFormat === FilePanelFileFormats.csv && <img alt={alt} src={csvSrc} />}
          {fileFormat === FilePanelFileFormats.svg && <img alt={alt} src={svgSrc} />}
          {fileFormat === FilePanelFileFormats.msg && <img alt={alt} src={msgSrc} />}
        </div>

        <span className={classes.fileName}>{fileName}</span>
      </button>
    );
  }
);
