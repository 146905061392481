import React, { BaseSyntheticEvent, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Popover,
  MenuItem as MaterialMenuItem,
  IconButton,
  useTheme,
  PopoverOrigin,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { withTooltip } from 'src/shared/hocs';

import { useStyle } from './NestedMenuStyles';
import { NestedMenuItem } from './NestedMenuItem';

export interface MenuItem {
  title?: string;
  action?: () => void;
  nestedMenu?: MenuItem[];
  icon?: JSX.Element;
  isAdditionalActionActive?: boolean;
  isActive?: boolean;
  additionalAction?: () => void;
  className?: string;
}

export interface NestedMenuProps {
  menuItems: MenuItem[];
  menuId: string;
  title?: string;
  buttonIcon?: JSX.Element;
  buttonClass?: string;
  icon?: JSX.Element;
  isAdditionalActionActive?: boolean;
  additionalAction?: () => void;
  isDisabled?: boolean;
  startIcon?: JSX.Element;
  namespaces?: string[];
  ariaLabel?: string;
  needTooltip?: boolean;
  tooltipTitle?: string;
  anchorOrigin?: PopoverOrigin;
}

interface OpenNestedMenuButtonProps {
  menuId: string;
  handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  buttonIcon?: JSX.Element;
  buttonClass?: string;
  ariaLabel?: string;
  startIcon?: JSX.Element;
  title?: string;
  namespaces?: string[];
}

const OpenNestedMenuButton = withTooltip(
  ({
    buttonIcon,
    buttonClass,
    ariaLabel,
    startIcon,
    title,
    namespaces,
    menuId,
    handleOpen,
    open,
  }: OpenNestedMenuButtonProps) => {
    const classes = useStyle();
    const { t } = useTranslation(namespaces);

    return (
      <>
        {buttonIcon ? (
          <IconButton
            size='small'
            className={clsx(classes.iconButton, buttonClass)}
            aria-label={ariaLabel}
            aria-describedby={menuId}
            onClick={handleOpen}
          >
            {buttonIcon}
          </IconButton>
        ) : (
          <Button
            className={clsx(classes.button, buttonClass)}
            startIcon={startIcon}
            endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            color='primary'
            aria-label={ariaLabel}
            aria-describedby={menuId}
            onClick={handleOpen}
          >
            <Typography noWrap className={classes.buttonText} variant='body1'>
              {t(title || '')}
            </Typography>
          </Button>
        )}
      </>
    );
  }
);

export const NestedMenu = (props: NestedMenuProps): JSX.Element => {
  const classes = useStyle();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    menuId,
    menuItems,
    title,
    buttonIcon,
    buttonClass,
    isDisabled,
    isAdditionalActionActive,
    additionalAction,
    startIcon,
    namespaces,
    ariaLabel,
    needTooltip,
    tooltipTitle,
    anchorOrigin,
  } = props;

  const { t } = useTranslation(namespaces);

  const handleStopPropagation = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  const handleAdditionalAction = (e: BaseSyntheticEvent): void => {
    e.stopPropagation();
    if (typeof additionalAction === 'function') {
      additionalAction();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      tabIndex={-1}
      role='button'
      onClick={handleStopPropagation}
      onKeyPress={handleStopPropagation}
    >
      <OpenNestedMenuButton
        open={open}
        needTooltip={needTooltip}
        tooltipTitle={tooltipTitle}
        langNs={namespaces}
        title={title}
        namespaces={namespaces}
        buttonIcon={buttonIcon}
        buttonClass={buttonClass}
        startIcon={startIcon}
        ariaLabel={ariaLabel}
        menuId={menuId}
        handleOpen={handleClick}
      />
      <Popover
        key={`${open}`}
        id={menuId}
        open={open}
        className={classes.itemsContainer}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ role: 'menubar', tabIndex: 0 }}
      >
        {menuItems.map((item: MenuItem) =>
          !item.nestedMenu ? (
            <MaterialMenuItem
              tabIndex={0}
              disabled={isDisabled}
              key={item.title}
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                if (typeof item.action === 'function') {
                  item.action();
                }
              }}
              style={{
                backgroundColor: item.isActive ? theme.palette.info.light : 'inherit',
              }}
            >
              <Typography
                variant='inherit'
                className={clsx(classes.menuItemText, item.className)}
              >
                {t(item.title || '')}
              </Typography>
              {item.icon ? (
                <IconButton
                  size='small'
                  onClick={handleAdditionalAction}
                  className={clsx(
                    classes.additionalActive,
                    isAdditionalActionActive ? classes.additionalActionActive : null
                  )}
                >
                  {item.icon}
                </IconButton>
              ) : null}
            </MaterialMenuItem>
          ) : (
            <NestedMenuItem
              isDisabled={isDisabled}
              key={item.title}
              item={item}
              closePopup={handleClose}
              namespaces={namespaces}
            />
          )
        )}
      </Popover>
    </div>
  );
};

NestedMenu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};
