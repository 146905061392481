import { IStoreSnapshotOut } from './models';
import { insuranceObjectDetailsFieldNames } from './models';

export const defaultStoreState: IStoreSnapshotOut = {
  infoForOfferStore: {
    insuranceType: 0,
    newInsuranceObjectCount: 1,
    changeInsuranceObjectCount: 1,
    postalCode: null,
  },
  user: {
    name: null,
    email: null,
    numberOfInsuredCars: null,
    phoneNumber: null,
    deviatingBegin: null,
    isValid: null,
  },
  offer: {
    rentalCarSinglePremium: null,
    totalBonusPerMonthSinglePremium: null,
    totalBonusPerMonthSinglePremiumWithGross: 1800,
    sumInsured: 100000000,
    coinsuranceOfOwnDamageUpTo: 150000,
    fullyComprehensive: 1000,
    partiallyComprehensive: 500,
    consequentialDamageUpTo: 6000,
  },
  specialOffer: {},
  insuranceObjectDetails: {
    isPaymentDataValid: false,
    isBillingAddressValid: false,
    isFormValid: false,
    isContactDataValid: false,
    items: [],
    [insuranceObjectDetailsFieldNames.company]: null,
    [insuranceObjectDetailsFieldNames.firstAndLastName]: null,
    [insuranceObjectDetailsFieldNames.streetAndHouseNumber]: null,
    [insuranceObjectDetailsFieldNames.city]: null,
    [insuranceObjectDetailsFieldNames.email]: null,
    [insuranceObjectDetailsFieldNames.phoneNumber]: null,
    [insuranceObjectDetailsFieldNames.paymentMethod]: null,
    [insuranceObjectDetailsFieldNames.iban]: null,
    [insuranceObjectDetailsFieldNames.bic]: null,
    [insuranceObjectDetailsFieldNames.bank]: null,
  },
  confirmations: {
    protectionRegulation: false,
    brokerageContract: false,
    generalConditionsForMotor: false,
    agreeToSendInformation: true,
  },
};
