import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { lightTheme } from './theme';

export const withTheme = (component: () => React.ReactNode) => () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {component()}
    </ThemeProvider>
  );
};
