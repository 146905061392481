import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCloseBrowserTabBtnStyles = makeStyles((theme: Theme) => {
  return {
    closeBtn: {
      fontSize: '1.4rem',
      fontWeight: theme.typography.fontWeightRegular,
    },
  };
});
