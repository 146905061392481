import React, { ReactElement } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';

import { RoutedContent } from 'src/routing';
import {
  usePathInterceptor,
  useLinkIdInterceptor,
  useEmailInterceptor,
  useQuery,
  useTimestampInterceptor,
  useAppVersionInterceptor,
} from 'src/shared/hooks';
import { useStore } from 'src/mst/StoreProvider';
import { getUserStore } from 'src/mst/selectors';
import { reactQueryKeys } from 'src/shared/constants';
import { Loader } from 'src/components/base';

function App(): ReactElement {
  const linkId = useQuery().get('linkId');
  const email = useQuery().get('user');

  const store = useStore();
  const userStore = getUserStore(store);

  usePathInterceptor();
  useLinkIdInterceptor(linkId);
  useEmailInterceptor(email);
  useTimestampInterceptor();
  useAppVersionInterceptor();

  const { isLoading } = useReactQuery(
    [reactQueryKeys.userData],
    userStore.fetchUserData
    //{ enabled: Boolean(linkId) || Boolean(email) }
  );

  if (isLoading && linkId) {
    return <Loader />;
  }

  return <RoutedContent />;
}

export default App;
