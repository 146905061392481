import React, { ReactElement, SyntheticEvent } from 'react';

import { Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useHelpInfoStyles, useHelpInfoTooltipStyles } from './HelpInfoStyles';

interface HelpInfoProps {
  title: string;
  text: string;
  icon?: ReactElement;
}

export const HelpInfo = ({ title, text, icon }: HelpInfoProps): ReactElement => {
  const classes = useHelpInfoStyles();
  const tooltipClasses = useHelpInfoTooltipStyles();

  const stopPropagation = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  return (
    <Tooltip
      arrow
      disableFocusListener
      enterTouchDelay={0}
      leaveTouchDelay={10000}
      title={
        <div>
          {title && (
            <Typography className={classes.title} variant='subtitle1'>
              {title}
            </Typography>
          )}
          {text && (
            <Typography className={classes.text} variant='body1'>
              {text}
            </Typography>
          )}
        </div>
      }
      onClick={stopPropagation}
      classes={tooltipClasses}
    >
      {icon ? icon : <HelpIcon color='info' className={classes.icon} />}
    </Tooltip>
  );
};
