import { useQuery } from './useQuery';

export const useUrlParameters = (): string => {
  const linkId = useQuery().get('linkId');
  const email = useQuery().get('user');

  if (linkId) {
    return `?linkId=${linkId}`;
  } else if (email) {
    return `?user=${email}`;
  } else {
    return '';
  }
};
