import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { buildFormData } from 'src/shared/utils';
import { SpecialOfferFormType } from 'src/pages/SpecialOfferPage/SpecialOfferPage';

export const SpecialOfferStore = types.model('SpecialOfferStore', {}).actions((self) => {
  let initialState: ISpecialOfferStoreStoreSnapshotOut;
  const {
    env: { httpClient },
  } = getEnv(self);
  return {
    afterCreate(): void {
      initialState = getSnapshot(self);
    },
    resetSpecialOfferStoreData(): void {
      applySnapshot(self, initialState);
    },
    send: flow(function* (data: SpecialOfferFormType) {
      const formattedData = {
        name: data.firstAndLastNames,
        email: data.email,
        phoneNumber: data.phoneNumber,
        documents: data.attachments.map((item) => item.file),
      };
      const formData = new FormData();
      buildFormData(formData, formattedData, null);
      yield httpClient.put(`insurance/submit-documents`, formData);
    }),
  };
});

export type SpecialOfferStoreStoreModel = Instance<typeof SpecialOfferStore>;
export type ISpecialOfferStoreStoreSnapshotOut = SnapshotOut<typeof SpecialOfferStore>;
export type ISpecialOfferStoreStoreSnapshotIn = SnapshotIn<typeof SpecialOfferStore>;
