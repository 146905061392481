import { makeStyles } from '@mui/styles';

export const useDropZoneItemStyles = makeStyles(() => {
  return {
    container: {
      position: 'relative',
      width: 70,
      minHeight: 70,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      transition: '0.2s',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 70,
      height: 70,
      '& img': {
        width: '100%',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '4rem',
      },
    },
    fileName: {
      display: 'block',
      width: '100%',
      fontSize: '1.2rem',
      marginTop: '0.5em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    deleteBtn: {
      position: 'absolute',
      top: -20,
      right: -10,
      zIndex: 100,
    },
  };
});
