import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LinearProgress } from '@mui/material';

import routingConfig from './routingConfig';
import CustomRoute from './Route';

export const RoutedContent = observer((): JSX.Element => {
  const config = routingConfig();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        {config.map((route) => {
          const { path, children, component: Component, validators } = route;

          return (
            <Route
              key={path}
              element={<CustomRoute path={path} validators={validators} />}
              {...route}
            >
              <Route path={path} element={<Component />}>
                {Array.isArray(children) &&
                  children.map((childRoute) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={childRoute.path}
                        element={
                          <CustomRoute
                            path={childRoute.path}
                            validators={childRoute.validators}
                          />
                        }
                        {...childRoute}
                      >
                        <Route path={path} element={<ChildComponent />} />
                      </Route>
                    );
                  })}
              </Route>
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
});
