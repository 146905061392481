import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';

export const useLinkIdInterceptor = (linkId: string): void => {
  const axios = useAxios();

  useEffect(() => {
    let reqInterceptor: number;
    let resInterceptor: number;
    if (linkId) {
      const linkIdInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (config.headers) {
          config.headers['link-id'] = linkId;
        }
        return config;
      };

      reqInterceptor = axios.interceptors.request.use(linkIdInterceptor);
    }
    return () => {
      if (reqInterceptor && resInterceptor) {
        axios.interceptors.request.eject(reqInterceptor);
      }
    };
  }, [linkId]);
};
