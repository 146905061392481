import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { NestedMenu, MenuItem } from '../NestedMenu';
import { Languages } from 'src/shared/constants';

import { useLanguageDetectorStyle } from './LanguageSelectorStyles';

const languageNS = ['common'];

export const LanguageSelector = (): ReactElement => {
  const classes = useLanguageDetectorStyle();
  const { i18n } = useTranslation(['common']);

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  const menuItems: MenuItem[] = [
    {
      title: 'common:fullLanguageNames.en',
      action: () => handleChangeLanguage(Languages.EN),
      isActive: i18n.language === Languages.EN,
    },
    {
      title: 'common:fullLanguageNames.de',
      action: () => handleChangeLanguage(Languages.DE),
      isActive: i18n.language === Languages.DE,
    },
  ];

  return (
    <NestedMenu
      menuId='language-selector'
      buttonClass={classes.langBtn}
      menuItems={menuItems}
      title={`common:languagesAbbr.${i18n.language}`}
      namespaces={languageNS}
    />
  );
};

LanguageSelector.defaultProps = {
  color: 'primary',
};
