import React, { ReactElement, CSSProperties, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => {
  return {
    tooltip: {
      fontSize: '1.4rem',
      '&.MuiTooltip-popper': {},
    },
    popper: {
      zIndex: 15000,
    },
  };
});

interface withTooltipProps {
  needTooltip: boolean;
  langNs: string[];
  tooltipTitle?: string;
  wrapperStyles?: CSSProperties;
  tooltipElement?: ReactElement;
  disableInteractive?: boolean;
}

export const withTooltip = function <T>(
  Component: ComponentType<T>
): ComponentType<T & withTooltipProps> {
  return ({
    needTooltip,
    tooltipTitle,
    wrapperStyles,
    langNs,
    tooltipElement,
    disableInteractive = false,
    ...rest
  }: T & withTooltipProps): ReactElement => {
    const { t } = useTranslation(langNs);
    const classes = useStyle();
    const restParams: unknown = { ...rest };
    if (needTooltip && (tooltipTitle || tooltipElement)) {
      return (
        <Tooltip
          disableInteractive={disableInteractive}
          classes={classes}
          title={tooltipElement || t(tooltipTitle)}
        >
          <div style={wrapperStyles}>
            <Component {...(restParams as T)} />
          </div>
        </Tooltip>
      );
    }
    return <Component {...(restParams as T)} />;
  };
};
