import { Components } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { defaultThemeLightColors } from './defaultThemeColors';

export const defaultLightThemeComponents: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        fontSize: '10px',
      },
      body: {
        height: '100%',
        margin: '0px auto',
      },
      a: {
        color: defaultThemeLightColors.secondaryDarkColor,
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '1.6rem',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      'div,ul,tbody': {
        scrollbarWidth: 'thin',
        scrollbarColor: `#888888 #595959`,
        '&::-webkit-scrollbar': {
          width: 12,
          height: 6,
        },
        '&::-webkit-scrollbar-track': {
          background: defaultThemeLightColors.primaryDarkColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: defaultThemeLightColors.secondaryMainColor,
        },
      },
      '& .MuiCalendarPicker-root': {
        '& .MuiPickersDay-root': {
          fontSize: '1.6rem',
        },
        '& .MuiTypography-root': {
          fontSize: '1.6rem',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      containedSecondary: {
        backgroundColor: defaultThemeLightColors.secondaryDarkColor,
        '&:hover': {
          backgroundColor: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlinedSecondary: {
        borderColor: defaultThemeLightColors.secondaryDarkColor,
        color: defaultThemeLightColors.secondaryDarkColor,
        '&:hover': {
          borderColor: defaultThemeLightColors.secondaryMainColor,
          color: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlinedPrimary: {
        borderColor: defaultThemeLightColors.primaryContrastText,
        color: defaultThemeLightColors.primaryContrastText,
        '&:hover': {
          borderColor: defaultThemeLightColors.secondaryMainColor,
          color: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 400,
        color: '#4D4D4D',
        textDecoration: 'underline',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: defaultThemeLightColors.errorMainColor,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: defaultThemeLightColors.infoMainColor,
      },
      arrow: {
        '&::before': {
          backgroundColor: defaultThemeLightColors.infoMainColor,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '&:not(.Mui-checked)': {
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${defaultThemeLightColors.secondaryDarkColor}!important`,
          color: defaultThemeLightColors.secondaryContrastText,
        },
      },
      today: {
        backgroundColor: `${defaultThemeLightColors.infoMainColor}!important`,
        color: defaultThemeLightColors.infoContrastText,
        border: `1px solid ${defaultThemeLightColors.infoContrastText}!important`,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeLightColors.primaryMainColor,
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeLightColors.primaryMainColor,
        '&>div:nth-of-type(2)>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
            color: defaultThemeLightColors.secondaryContrastText,
          },
        },
        '&>div>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
            color: defaultThemeLightColors.secondaryContrastText,
          },
        },
      },
    },
  },
};
