import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { useCloseBrowserTabBtnStyles } from './CloseBrowserTabBtnStyles';

export const CloseBrowserTabBtn = (): ReactElement => {
  const classes = useCloseBrowserTabBtnStyles();
  const { t } = useTranslation(['common']);

  const onClick = (): void => {
    window.open('about:blank', '_self');
    window.close();
  };

  return (
    <Button onClick={onClick} color='info' className={classes.closeBtn}>
      {t('closeBtn')}
    </Button>
  );
};
