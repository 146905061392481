import React, { ReactElement, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { mimeTypes, useMimeTypeFromFormat } from 'src/shared/hooks';

import { useDropZoneStyles } from './DropZoneStyles';
import { DropZoneItem } from './DropZoneItem';

interface DropeZoneProps {
  langNs: string[];
  attachments: { id: string; file: File }[];
  handleChange: (value: File[]) => void;
  handleDelete: (id: string) => void;
  placeholder?: string;
  availableFormats?: string[];
  id?: string;
}

export const DropeZone = ({
  id,
  langNs,
  placeholder,
  attachments,
  handleChange,
  handleDelete,
  availableFormats,
}: DropeZoneProps): ReactElement => {
  const { t } = useTranslation(langNs);
  const classes = useDropZoneStyles();

  const availableMimeTypes = useMimeTypeFromFormat(availableFormats);

  const accept = useMemo(() => {
    if (!availableMimeTypes || !availableMimeTypes.length) {
      return null;
    }
    return Object.fromEntries(
      availableMimeTypes.map((mimeType) => {
        if (mimeType === mimeTypes.msg) {
          return [mimeType, ['.msg']];
        }
        return [mimeType, []];
      })
    );
  }, [availableMimeTypes]);

  const onDrop = (acceptedFiles: File[]): void => {
    handleChange(acceptedFiles);
  };

  const onDelete = (id: string): void => {
    handleDelete(id);
  };

  const isFiles = useMemo(
    () => Boolean(Array.isArray(attachments) && attachments.length),
    [attachments]
  );

  return (
    <Dropzone onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <section className={classes.container}>
          <div {...getRootProps()} className={classes.presentation}>
            <input id={id} {...getInputProps()} />
            {!isFiles && (
              <div className={classes.addAttachmentContainer}>
                <AddCircleIcon color='info' className={classes.addAttachmentIcon} />
                <Typography variant='body1' className={classes.addAttachmentText}>
                  {t(placeholder)}
                </Typography>
              </div>
            )}
            {isFiles && (
              <div className={classes.itemsContainer}>
                {attachments.map((item) => (
                  <DropZoneItem key={item.id} onDelete={onDelete} item={item} />
                ))}
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};
