import React, { ChangeEvent, ReactElement, useState, useEffect } from 'react';

import { IconButton, InputBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useCounterStyles } from './CounterStyles';

interface CounterProps {
  value: number;
  handleIncrease: () => void;
  handleDecrease: () => void;
  handleChange: (value: number) => void;
  disabled?: boolean;
}

const valueFontSize = 14; // if it is changed in styles, should be changed here
const defaultInputWidth = 20;

export const Counter = ({
  disabled,
  value,
  handleIncrease,
  handleDecrease,
  handleChange,
}: CounterProps): ReactElement => {
  const [inputWidth, setInputWidth] = useState(defaultInputWidth);
  const classes = useCounterStyles({ valueFontSize });

  useEffect(() => {
    const stringValue = typeof value === 'number' ? `${value}` : '';
    if (stringValue.length * valueFontSize > defaultInputWidth) {
      setInputWidth(stringValue.length * valueFontSize);
    } else {
      setInputWidth(defaultInputWidth);
    }
  }, [value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    handleChange(Number(e.target.value) || 0);
  };

  return (
    <div className={classes.container}>
      <IconButton className={classes.btn} disabled={disabled} onClick={handleDecrease}>
        <RemoveIcon />
      </IconButton>
      <InputBase
        inputProps={{ type: 'number' }}
        style={{ width: inputWidth }}
        className={classes.value}
        value={value}
        onChange={onChange}
      />
      <IconButton className={classes.btn} disabled={disabled} onClick={handleIncrease}>
        <AddIcon />
      </IconButton>
    </div>
  );
};
