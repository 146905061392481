import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useHelpInfoStyles = makeStyles((theme: Theme) => {
  return {
    icon: {
      fontSize: '2rem',
      cursor: 'default',
    },
    title: {
      fontSize: '1.4rem',
      color: theme.palette.info.contrastText,
    },
    text: {
      fontSize: '1.2rem',
      color: theme.palette.info.contrastText,
    },
  };
});

export const useHelpInfoTooltipStyles = makeStyles(() => {
  return {
    tooltip: {
      fontSize: '1.2rem',
    },
  };
});
