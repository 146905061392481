export const useTimestamp = (): string => {
  const timestamp = sessionStorage.getItem('timestamp');
  if (!timestamp) {
    const value = new Date(Date.now()).toISOString();
    sessionStorage.setItem('timestamp', value);
    return value;
  }

  return timestamp;
};
