import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  getRoot,
} from 'mobx-state-tree';

import { StoreModel } from '../root';
import { getInsuranceObjectDetailsStore } from 'src/mst/selectors';

import { insuranceObjectDetailsFieldNames } from './constants';

export const InsuranceObjectDetailsItemStore = types
  .model('InsuranceObjectDetailsItemStore', {
    isValid: types.maybeNull(types.boolean),
    [insuranceObjectDetailsFieldNames.insuranceObjectId]: types.string,
    [insuranceObjectDetailsFieldNames.currentInsurer]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.licensePlate]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.firstRegistration]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.hsn]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.tsn]: types.maybeNull(types.string),
    [insuranceObjectDetailsFieldNames.fin]: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IInsuranceObjectDetailsItemStoreSnapshotOut;
    const store: StoreModel = getRoot(self);
    const insuranceObjectDetails = getInsuranceObjectDetailsStore(store);

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetInsuranceObjectDetailsStoreData(): void {
        applySnapshot(self, initialState);
      },
      setValid(value: boolean) {
        self.isValid = value;
        insuranceObjectDetails.validate();
      },
      setFieldValue(name: string, value: unknown): void {
        const isUpperCase =
          name === insuranceObjectDetailsFieldNames.fin ||
          name === insuranceObjectDetailsFieldNames.tsn ||
          name === insuranceObjectDetailsFieldNames.hsn;

        const valueToSet = isUpperCase ? (value as string).toUpperCase() : value;
        applySnapshot(self, {
          ...self,
          [name]: valueToSet,
        });
      },
    };
  });

export type InsuranceObjectDetailsItemStoreModel = Instance<
  typeof InsuranceObjectDetailsItemStore
>;
export type IInsuranceObjectDetailsItemStoreSnapshotOut = SnapshotOut<
  typeof InsuranceObjectDetailsItemStore
>;
export type IInsuranceObjectDetailsItemStoreSnapshotIn = SnapshotIn<
  typeof InsuranceObjectDetailsItemStore
>;
