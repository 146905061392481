import React, { ReactElement } from 'react';

import { ListItem, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { useCheckedListItemStyles } from './CheckedListItemStyles';

interface CheckedListItemProps {
  children: ReactElement | ReactElement[];
}

export const CheckedListItem = ({ children }: CheckedListItemProps): ReactElement => {
  const classes = useCheckedListItemStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        <CheckIcon className={classes.icon} />
      </ListItemIcon>
      {children}
    </ListItem>
  );
};
