import { createTheme } from '@mui/material/styles';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';

import { appInstance } from 'src/environment';

import {
  themeSpacingsFactory,
  themeBreakpointsFacotry,
  themeTypographyFactory,
  themeComponentsFactory,
  themePaletteFactory,
} from './themeFactories';

export const lightTheme = createTheme({
  spacing: themeSpacingsFactory(appInstance),
  breakpoints: themeBreakpointsFacotry(appInstance),
  typography: themeTypographyFactory(appInstance),
  components: themeComponentsFactory(appInstance),
  palette: themePaletteFactory(appInstance),
});

export const themes = {
  lightTheme: responsiveFontSizes(lightTheme),
};
