export enum FilePanelFileFormats {
  xls = 'xls',
  xlsx = 'xlsx',
  docx = 'docx',
  doc = 'doc',
  pdf = 'pdf',
  dmsPdf = 'DMS-PDF',
  png = 'png',
  jpg = 'jpg',
  csv = 'csv',
  svg = 'svg',
  msg = 'msg',
}
