export enum defaultThemeLightColors {
  primaryMainColor = '#F9F9F9',
  primaryLightColor = '#FFFFFF',
  primaryDarkColor = '#F5F5F5',
  primaryContrastText = '#4D4D4D',
  secondaryMainColor = '#D0352F',
  secondaryLightColor = '#D0352F', // Not specified in designs, used the same as for main
  secondaryDarkColor = '#D0352F', // Not specified in designs, used the same as for main
  secondaryContrastText = '#FFFFFF',
  infoMainColor = '#0083A9',
  infoLightColor = '#E6F4F8',
  infoDarkColor = '#0083A9', // Not specified in designs, used the same as for main
  infoContrastText = '#FFFFFF',
  errorDarkColor = '#CB362D',
  errorMainColor = '#FD1717',
  errorLightColor = 'rgba(203, 54, 45, 0.1)',
  errorContrastTextColor = '#ffffff',
  hoverInfoButton = '#0BDFDF',
  mainBorderColor = '#D2D2D2',
}
